<template>
  <div class="app-container">
    <el-card class="box-card conter">
      <div class="title">注册</div>
      <el-form :model="Form" :rules="rules" ref="Form" class="Form">
        <el-form-item prop="email">
          <el-input
            v-model="Form.email"
            spellcheck="false"
            autocomplete="on"
            placeholder="请输入邮箱(xxxx@xxx.com)"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="code" class="smsCode-wrapper">
          <div class="item-wrapper">
            <div class="el-input-wrapper">
              <el-input
                placeholder="请输入邮箱验证码"
                v-model="Form.code"
              ></el-input>
            </div>
            <button
              type="button"
              :class="!show ? 'send-button_s block' : 'send-button block'"
            >
              <span v-show="show" @click="getCode">发送验证码</span>
              <span v-show="!show" class="count">{{ count }}s后重新发送</span>
            </button>
          </div>
        </el-form-item> -->
        <el-form-item prop="title">
          <el-input
            v-model="Form.title"
            autocomplete="off"
            placeholder="请输入机构名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            ref="pass"
            :type="pwdType"
            v-model="Form.password"
            autocomplete="off"
            placeholder="请输入密码"
          ></el-input>
          <span class="show-pwd" @click="showPwd">
            <svg-icon icon-class="eye" />
          </span>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input
            ref="checkPass"
            :type="checkPassType"
            v-model="Form.checkPass"
            autocomplete="off"
            placeholder="请再次输入并且确认密码"
          ></el-input>
          <span class="show-pwd" @click="showcheckPass">
            <svg-icon icon-class="eye" />
          </span>
        </el-form-item>
        <el-form-item prop="logo_id">
          <el-upload
            class="avatar-uploader"
            action
            list-type="picture-card"
            :show-file-list="false"
            :before-upload="uploadFile"
          >
            <img v-if="Form.logo_id" :src="Form.imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item class="submit-wrapper">
          <button type="button" class="submit" @click="submitForm('Form')">
            立即注册
          </button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {
  validEmail,
  isPassWord,
  isSmsCode,
  isUserName,
} from "@/utils/validate";
import { image } from "@/api/image";
import { register } from "@/api/organ";
import App from "@/utils/configApp";
import jump from "@/utils/loginWebsite";
import isAuthorization from "@/utils/authorization";
import { setEncryption } from "@/mixins";
export default {
  mixins: [setEncryption],
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error("邮箱不符合规则"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (!isSmsCode(value)) {
        callback(new Error("验证码不符合规则"));
      } else {
        callback();
      }
    };
    // const validateTitle = (rule, value, callback) => {
    //   if (!isUserName(value)) {
    //     callback(new Error("用户名不符合规则"));
    //   } else {
    //     callback();
    //   }
    // };
    const validatePass = (rule, value, callback) => {
      if (!isPassWord(value)) {
        callback(new Error("请输入至少6位"));
      } else {
        if (this.Form.checkPass !== "") {
          this.$refs.Form.validateField("checkPass");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (!isPassWord(value)) {
        callback(new Error("请输入至少6位"));
      } else if (value !== this.Form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      pwdType: "password",
      checkPassType: "password",
      show: true,
      count: 0,
      Form: {
        email: "",
        code: "",
        password: "",
        checkPass: "",
        logo_id: "",
        title: "",
        imageUrl: "",
      },
      rules: {
        email: [{ required: true, trigger: "blur", validator: validateEmail }],
        pass: [{ required: true, trigger: "blur", validator: validatePass }],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
        title: [
          {
            required: true,
            trigger: "blur",
            message: "请填写机构名称",
            //  validator: validateTitle
          },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        logo_id: {
          required: true,
          message: "请上传机构logo",
          trigger: "change",
        },
      },
    };
  },
  computed: {
    agent_token() {
      return this.$store.getters.agent_token;
    },
    app() {
      return App;
    },
  },
  methods: {
    uploadFile(file) {
      let _this = this;
      let formdata = new FormData();
      formdata.append("image", file);
      formdata.append("title", file.name);
      image(formdata).then((response) => {
        if (response.status_code == 1) {
          // console.log(response);
          _this.Form.logo_id = response.data.id;
          _this.Form.imageUrl = response.data.url;
        }
      });
      return false;
    },
    getCode() {},
    submitForm(name, event) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          if (this.agent_token) {
            let form = { ...this.Form };
            form.password = this.encryption(form.password);
            form.checkPass = this.encryption(form.checkPass);
            await register(form);
            this.$message({
              type: "success",
              message: "注册成功(需审核后登录)",
            });
            this.$router.push({name:"login"})
          } else {
            this.open();
          }
        }
      });
    },
    showPwd() {
      if (this.pwdType === "password") {
        this.pwdType = "";
      } else {
        this.pwdType = "password";
      }
      this.$nextTick(() => {
        this.$refs.pass.focus();
      });
    },
    showcheckPass() {
      if (this.checkPassType === "password") {
        this.checkPassType = "";
      } else {
        this.checkPassType = "password";
      }
      this.$nextTick(() => {
        this.$refs.checkPass.focus();
      });
    },
    open() {
      let _this = this;
      const uri = this.app.uri;
      const ID = this.app.ID;
      let href = window.location.href;
      this.$alert("尊敬的用户您好，您需要登录(登录完成后继续操作)！", "提示", {
        distinguishCancelAndClose: true,
        showClose: false,
        confirmButtonText: "立即登录",
      }).then(() => {
        jump(uri, encodeURIComponent(href), ID);
      });
    },
  },
  mounted() {
    if (!this.agent_token) {
      this.open();
    }
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    isAuthorization(to, next);
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/elForm.scss";
.app-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: url("../../assets/image/login_bg.png") no-repeat;
  background-size: cover;
  color: #333;
  .conter {
    margin: 0 auto;
    width: 450px;
    height: 600px;
    /deep/ .el-card__body {
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      .title {
        font-size: 25px;
        margin-bottom: 25px;
      }
    }
  }
  /deep/.avatar-uploader {
    .el-upload {
      border: 1px dashed #cecbcb;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      width: 80px;
      height: 80px;
      line-height: 80px;
      overflow: hidden;
      &:hover {
        border-color: #409eff;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 78px;
      height: 78px;
      line-height: 78px;
      text-align: center;
    }
    .avatar {
      width: 78px;
      height: 78px;
      display: block;
    }
  }
}
</style>