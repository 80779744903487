import store from '../store'

function isAuthorization(to, next) {
    if (to.query.token) {
        //登录成功携带token
        store
            .dispatch("Login_token", { token: decodeURIComponent(to.query.token) })
            .then(() => {
                next({ path: to.path, query: {}, replace: true });
            });
    } else {
        next();
    }
}

export default isAuthorization